import Dashboard from "views/Dashboard.js";
import UserManagement from "views/UserManagement.js";
import Login from "views/Login.js";
import News from "views/News.js";
import CreateNews from "views/CreateNews";
import EditNews from "views/EditNews";
import Empty from "views/Empty";
import IngredientManagement from "views/IngredientManagement";
import CreateIngredient from "views/CreateIngredient";
import EditIngredient from "views/EditIngredient";
import EditUserProfile from "views/EditUserProfile";

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "home.png",
    component: <Login />,
    layout: "/auth",
    hidden: true,
    requires_login: false,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "home.png",
    component: <Dashboard />,
    layout: "/admin",
    hidden: false,
    requires_login: true,
  },
  {
    path: "/user-management",
    name: "User Management",
    icon: "group.png",
    component: <UserManagement />,
    layout: "/admin",
    hidden: false,
    requires_login: true,
  },
  {
    path: "/profile/edit/:user_id",
    name: "Edit User Profile",
    icon: "group.png",
    component: <EditUserProfile />,
    layout: "/admin",
    hidden: true,
    requires_login: true,
  },
  {
    path: "/ingredient-management",
    name: "Ingredient Management",
    icon: "food.png",
    component: <IngredientManagement />,
    layout: "/admin",
    hidden: false,
    requires_login: true,
  },
  {
    path: "/create-ingredient",
    name: "Create Ingredients",
    icon: "food.png",
    component: <CreateIngredient />,
    layout: "/admin",
    hidden: true,
    requires_login: true,
  },
  {
    path: "/ingredient/edit/:ingredient_id",
    name: "Edit Ingredients",
    icon: "food.png",
    component: <EditIngredient />,
    layout: "/admin",
    hidden: true,
    requires_login: true,
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    icon: "money.png",
    component: <Empty />,
    layout: "/admin",
    hidden: false,
    requires_login: true,
  },
  {
    path: "/news",
    name: "News",
    icon: "megaphone.png",
    component: <News />,
    layout: "/admin",
    hidden: false,
    requires_login: true,
  },
  {
    path: "/create-news",
    name: "Create News",
    icon: "megaphone.png",
    component: <CreateNews />,
    layout: "/admin",
    hidden: true,
    requires_login: true,
  },
  {
    path: "/news/edit/:news_id",
    name: "Edit News",
    icon: "megaphone.png",
    component: <EditNews />,
    layout: "/admin",
    hidden: true,
    requires_login: true,
  },
  {
    path: "/account-management",
    name: "Account Management",
    icon: "user.png",
    component: <Empty />,
    layout: "/admin",
    hidden: false,
    requires_login: true,
  },
  {
    path: "/coupons",
    name: "Coupons",
    icon: "tag.png",
    component: <Empty />,
    layout: "/admin",
    hidden: false,
    requires_login: true,
  },
];
export default routes;
