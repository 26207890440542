import React, { useState, useRef, useEffect } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,FormGroup, Input, InputGroup
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import { Link } from "react-router-dom";
import axios from 'axios';
import BACKEND_URL from 'index';
import save_logo from '../assets/img/save.png';
import "../assets/customcss/CreateIngredient.css";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

function EditIngredient() {
  const { i18n } = useTranslation();
  let access_token = localStorage.getItem("access_token");
  let refresh_token = localStorage.getItem("refresh_token");
  const [name, setName] = useState('');
  const {ingredient_id} = useParams();
  const notificationAlertRef = useRef(null);
  
  const notify = (place) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            Please fill in all of the fields before continuing.
          </div>
        </div>
      ),
      type: "warning",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const refreshToken = async () => {
    try {
      const response = await axios.post(BACKEND_URL + '/users/login/refresh/',
      {
        refresh: refresh_token,
      },
      {
        headers: {
          'Accept-Language': i18n.language,
        },
      }
      );
      localStorage.setItem("access_token", response.data.access);
      access_token = response.data.access;
      localStorage.setItem("refresh_token", response.data.refresh);
      refresh_token = response.data.refresh
    } catch (error) {
      console.error('Refresh token failed:', error.message);
      window.location.href = '/auth/login'
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('is_staff');
    } finally {
      
    }
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      if (!name) {
        notify("tc");
        return;
      }  
      formData.append('name', name);

      await axios.put(BACKEND_URL + '/ingredients/edit/'+ingredient_id, formData,
        {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + access_token,
        },
      });

      window.location.href = "/admin/ingredient-management"

    } catch (error) {
      console.error('Save failed:', error.message);
      console.log(error);
      if (error.response && error.response.data && error.response.data.errors) {
        const errorCode = error.response.data.errors[0].code;
        if (errorCode === "token_not_valid") {
          try {
            await refreshToken();
            await handleSave();
            return;
          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError.message);
            window.location.href = '/auth/login';
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('is_staff');
          }
        }
      }
    }
  };

  const getIngredientDetails = async () => {
    try {
      const response = await axios.get(BACKEND_URL + '/ingredients/get/' + ingredient_id,
        {
        headers: {
          'Authorization': 'Bearer ' + access_token,
        },
      });
      setName(response.data.name);
    } catch (error) {
      console.error('Save failed:', error.message);
      console.log(error);
      if (error.response && error.response.data && error.response.data.errors) {
        const errorCode = error.response.data.errors[0].code;
        if (errorCode === "token_not_valid") {
          try {
            await refreshToken();
            await getIngredientDetails();
            return;
          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError.message);
            window.location.href = '/auth/login';
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('is_staff');
          }
        }
      }
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getIngredientDetails();
  }, []);

  return (
    <>
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>
          <Col md="12">
            <Card style={{ backgroundColor:"#12110b" }}>
              <CardHeader className="d-flex justify-content-between align-items-center">
                  <div>
                    <CardTitle tag="h4" className="mb-0">Edit Ingredient</CardTitle>
                    <Link to="/admin/ingredient-management" style={{ marginRight: "10px", color:"white" }}>
                        Back
                    </Link>         
                  </div>
                  <div>
                    <Button onClick={() => {handleSave()}} style={{ background: "none", border: "1px solid white", borderRadius: "20px" }}>
                      <img src={save_logo} alt="Save" style={{ width: "20px", marginRight: "5px", filter: "invert(100%)" }}/>
                      Save
                    </Button>
                  </div>
              </CardHeader>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <FormGroup className="name-div-formgroup">
                    <div className="name-div">
                          <label htmlFor="name" className="form-label">Name</label>
                          <InputGroup className="input-group-alternative">
                          <Input
                              id="name"
                              placeholder="Name"
                              type="text"
                              autoComplete="new-name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              maxLength={100}
                              className="input-field"
                            />
                      </InputGroup>
                    </div>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default EditIngredient;
