import React, { useState, useRef } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,FormGroup, Input, InputGroup
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import { Link } from "react-router-dom";
import axios from 'axios';
import BACKEND_URL from 'index';
import save_logo from '../assets/img/save.png';
import "../assets/customcss/CreateNews.css";
import { useTranslation } from 'react-i18next';

function CreateNews() {
  const { i18n } = useTranslation();
  let access_token = localStorage.getItem("access_token");
  let refresh_token = localStorage.getItem("refresh_token");
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [newsContent, setNewsContent] = useState('');
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [enabled, setEnabled] = useState(true);
  const [enableAtDate, setEnableAtDate] = useState('');
  const fileInputRef = React.createRef();
  const notificationAlertRef = useRef(null);
  
  const notify = (place) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            Please fill in all of the fields before continuing.
          </div>
        </div>
      ),
      type: "warning",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const refreshToken = async () => {
    try {
      const response = await axios.post(BACKEND_URL + '/users/login/refresh/',
      {
        refresh: refresh_token,
      },
      {
        headers: {
          'Accept-Language': i18n.language,
        },
      }
      );
      localStorage.setItem("access_token", response.data.access);
      access_token = response.data.access;
      localStorage.setItem("refresh_token", response.data.refresh);
      refresh_token = response.data.refresh
    } catch (error) {
      console.error('Refresh token failed:', error.message);
      window.location.href = '/auth/login'
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('is_staff');
    } finally {
      
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setBackgroundImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setBackgroundImage(null);
    setImageFile(null);
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      if (!title || !date || !newsContent || !imageFile || enabled === null) {
        notify("tc");
        return;
      }  
      formData.append('title', title);
      formData.append('news_date', date);
      formData.append('short_description', shortDescription);
      formData.append('description', newsContent);
      formData.append('background_image', imageFile);
      formData.append('enabled', enabled);

      if (enableAtDate) {
        formData.append('enabled_at', enableAtDate);
      }
  
      await axios.post(BACKEND_URL + '/news/create/', formData,
        {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + access_token,
        },
      });

      window.location.href = "/admin/news"

    } catch (error) {
      console.error('Save failed:', error.message);
      console.log(error);
      if (error.response && error.response.data && error.response.data.errors) {
        const errorCode = error.response.data.errors[0].code;
        if (errorCode === "token_not_valid") {
          try {
            await refreshToken();
            await handleSave();
            return;
          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError.message);
            window.location.href = '/auth/login';
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('is_staff');
          }
        }
      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  

  return (
    <>
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>
          <Col md="12">
            <Card style={{ backgroundColor:"#12110b" }}>
              <CardHeader className="d-flex justify-content-between align-items-center">
                  <div>
                    <CardTitle tag="h4" className="mb-0">Create News</CardTitle>
                    <Link to="/admin/news" style={{ marginRight: "10px", color:"white" }}>
                        Back
                    </Link>         
                  </div>
                  <div>
                    <Button onClick={() => {handleSave()}} style={{ background: "none", border: "1px solid white", borderRadius: "20px" }}>
                      <img src={save_logo} alt="Save" style={{ width: "20px", marginRight: "5px", filter: "invert(100%)" }}/>
                      Save
                    </Button>
                  </div>
              </CardHeader>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <FormGroup className="title-div-formgroup">
                    <div className="title-div">
                          <label htmlFor="title" className="form-label">Title</label>
                          <InputGroup className="input-group-alternative">
                          <Input
                              id="title"
                              placeholder="Title"
                              type="text"
                              autoComplete="new-title"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              maxLength={100}
                              className="input-field"
                            />
                      </InputGroup>
                    </div>
                  </FormGroup>
                  <FormGroup className="date-div-formgroup">
                    <div className="date-div">
                          <label htmlFor="date" className="form-label">Date</label>
                          <InputGroup className="input-group-alternative">
                          <Input
                          id="date"
                          placeholder="Date"
                          type="date"
                          autoComplete="new-date"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                          className="input-field"
                        />
                      </InputGroup>
                    </div>
                  </FormGroup>   
                </div>
                <div>
                  <FormGroup className="short-description-div-formgroup">
                      <div className="short-description-div">
                        <label htmlFor="short-description" className="form-label">Short description</label>
                        <InputGroup className="input-group-alternative"> 
                        <Input
                            id="short-description"
                            placeholder="Short description"
                            type="textarea"
                            autoComplete="short-description"
                            value={shortDescription}
                            onChange={(e) => setShortDescription(e.target.value)}
                            maxLength={100}
                            className="input-field"
                            rows={1}
                          />
                        </InputGroup>
                      </div>
                  </FormGroup>
                </div>
                <div>
                  <FormGroup className="news-content-div-formgroup">
                      <div className="news-content-div">
                        <label htmlFor="news-content" className="form-label">News Content</label>
                        <InputGroup className="input-group-alternative"> 
                        <Input
                            id="news-content"
                            placeholder="News Content"
                            type="textarea"
                            autoComplete="news-content"
                            value={newsContent}
                            onChange={(e) => setNewsContent(e.target.value)}
                            maxLength={100}
                            className="input-field"
                            rows={6}
                          />
                        </InputGroup>
                      </div>
                  </FormGroup>
                </div>
                <div>
                  <FormGroup className="background-image-div-formgroup">
                    <div className="background-image-div">
                      <label htmlFor="background-image" className="form-label">Background Image</label>
                      <InputGroup className="input-group-alternative"> 
                      <input
                          id="background-image"
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          className="input-field"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                        />
                      <div>
                      {backgroundImage && (
                        <div className="uploaded-image">
                          <img src={backgroundImage} alt="Uploaded" className="uploaded-image-preview" />
                        </div>
                      )}
                      {!backgroundImage && (
                        <div className="button-group">
                          <Button 
                            color="primary" 
                            style={{ background: "none", border: "1px solid white", borderRadius: "20px" }} 
                            onClick={handleButtonClick}
                          >
                            Upload New Image
                          </Button>
                        </div>
                      )}
                      {backgroundImage && (
                          <div className="button-group">
                            <Button 
                                  color="primary" 
                                  style={{ background: "none", border: "1px solid white", borderRadius: "20px" }} 
                                  onClick={handleButtonClick}
                                >
                                  Upload New Image
                                </Button>
                            <Button color="danger" onClick={removeImage} style={{ background: "none", border: "1px solid white", borderRadius: "20px" }}>Remove Image</Button>
                          </div>
                      )}        
                      </div>
                      </InputGroup>
                      {/* Display the uploaded image if available */}
                    </div>
                  </FormGroup>
                </div>
                <div className="d-flex justify-content-between">
                <FormGroup className="enabled-div-formgroup">
                      <div className="enabled-div">
                        <Input
                            id="enabled"
                            type="checkbox"
                            checked={enabled}
                            onChange={(e) => setEnabled(e.target.checked)}
                            className="enabled-checkbox-news"
                        />
                        <label htmlFor="enabled" className="checkbox-label">Enabled</label>
                      </div>
                </FormGroup>
                  <FormGroup className="date-div-formgroup">
                    <div className="enable-at-div">
                          {!enabled && (<label htmlFor="enable-at" className="form-label">Optional enable at date:</label>)}
                          {enabled && (<label htmlFor="enable-at" className="form-label">Enable at date is disabled, given the news is enabled.</label>)}
                          <InputGroup className="input-group-alternative">
                          <Input
                          id="enable-at"
                          placeholder="Enable at date"
                          type="date"
                          autoComplete="enable-at-date"
                          value={enableAtDate}
                          onChange={(e) => setEnableAtDate(e.target.value)}
                          className='input-field'
                          style={{ pointerEvents: enabled ? 'none' : 'auto', opacity: enabled ? 0.5 : 1, backgroundColor: enabled ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0)'}}
                          disabled={enabled} 
                        />
                      </InputGroup>
                    </div>
                  </FormGroup>   
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CreateNews;
