import React, { useState, useEffect } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import edit_logo from '../assets/img/edit.png';
import search_logo from '../assets/img/search.png';
import axios from 'axios';
import BACKEND_URL from 'index';
import { useTranslation } from 'react-i18next';
import "../assets/customcss/UserManagement.css";
import avatar_1 from "../assets/img/avatars/1.png";

function UserManagement() {
  const { i18n } = useTranslation();
  let access_token = localStorage.getItem("access_token");
  let refresh_token = localStorage.getItem("refresh_token");
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [enableAnonymized, setEnableAnonymized] = useState(false);
  const [enableRealOnly, setEnableRealOnly] = useState(true);
  const [itemsPerPage] = useState(4);

  const refreshToken = async () => {
    try {
      const response = await axios.post(BACKEND_URL + '/users/login/refresh/',
      {
        refresh: refresh_token,
      },
      {
        headers: {
          'Accept-Language': i18n.language,
        },
      }
      );
      localStorage.setItem("access_token", response.data.access);
      access_token = response.data.access;
      localStorage.setItem("refresh_token", response.data.refresh);
      refresh_token = response.data.refresh
    } catch (error) {
      console.error('Refresh token failed:', error.message);
      window.location.href = '/auth/login'
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('is_staff');
    } finally {
      
    }
  };

  const getUsers = async () => {
    try {
      const response = await axios.get(BACKEND_URL + '/profiles/list/',
        {
        headers: {
          'Authorization': 'Bearer ' + access_token,
        },
      });

      setUsers(response.data);
      setFilteredUsers(response.data);
      } catch (error) {
        console.error('Save failed:', error.message);
        if (error.response && error.response.data && error.response.data.errors) {
          const errorCode = error.response.data.errors[0].code;
          if (errorCode === "token_not_valid") {
            try {
              await refreshToken();
              await getUsers();
              return;
            } catch (refreshError) {
              console.error('Token refresh failed:', refreshError.message);
              window.location.href = '/auth/login';
              localStorage.removeItem('access_token');
              localStorage.removeItem('refresh_token');
              localStorage.removeItem('is_staff');
            }
          }
        }
      }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getUsers();
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let filteredUsers = users;
  
    if (!enableAnonymized) {
      filteredUsers = filteredUsers.filter(user => user.user_is_active);
    }

    if (enableRealOnly) {
      filteredUsers = filteredUsers.filter(user => !user.user_is_staff);
    }
  
    // Set filteredUsers state after applying the filters
    setFilteredUsers(filteredUsers);
  }, [enableAnonymized, enableRealOnly, users]);
  

  // Logic to slice news array based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentIngredients = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  
  const handleSearch = (query) => {
    const searchTerm = query.toLowerCase();
    let filtered = users.filter(user => user.nickname.toLowerCase().includes(searchTerm));
  
    // Apply filters based on enableAnonymized and enableRealOnly
    if (!enableAnonymized) {
      filtered = filtered.filter(user => user.user_is_active);
    }
    if (enableRealOnly) {
      filtered = filtered.filter(user => !user.user_is_staff);
    }
  
    // Set filteredUsers state after applying all filters
    setFilteredUsers(filtered);
  };
  
  

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card style={{ backgroundColor:"#12110b" }}>
              <CardHeader className="d-flex justify-content-between align-items-center">
                <div>
                  <CardTitle tag="h4" className="mb-0">User Management</CardTitle>
                </div>
              </CardHeader>
              <CardBody>
              <div className='search-div'>
                <img src={search_logo} alt="Create New" style={{ width: "20px", marginRight: "5px", marginLeft:'20px', filter: "invert(100%)" }}/>
                <input
                  type="text"
                  placeholder="Search users..."
                  onChange={(e) => handleSearch(e.target.value)}
                  className='search-field'
                />
              </div>
              <div>
                <FormGroup className="enable-anonymized-div-formgroup">
                      <div className="enable-anonymized-div">
                        <Input
                            id="enable-anonymized"
                            type="checkbox"
                            checked={enableAnonymized}
                            onChange={(e) => setEnableAnonymized(e.target.checked)}
                            className="enable-anonymized-checkbox"
                        />
                        <label htmlFor="enable-anonymized" className="checkbox-label">Show anonymized accounts</label>
                      </div>
                </FormGroup>
                <FormGroup className="enable-real-only-div-formgroup">
                  <div className="enable-real-only-div">
                    <Input
                        id="enable-real-only"
                        type="checkbox"
                        checked={enableRealOnly}
                        onChange={(e) => setEnableRealOnly(e.target.checked)}
                        className="enable-anonymized-checkbox"
                    />
                    <label htmlFor="enable-real-only" className="checkbox-label">Show only real accounts</label>
                  </div>
                </FormGroup>  
                </div>
              <div className='ingredient-div'>
                {(filteredUsers.length > 0 ? filteredUsers : currentIngredients).map(userProfile => (
                  <div key={userProfile.id} className="ingredient-management-card">
                    <div className='ingredient-management-header'>
                      <div>
                      <img src={avatar_1} alt='avatar'/>
                      <span className='ingredient-management-title'>{userProfile.nickname}</span>
                      </div>
                      <div className='edit-ingredient-management-button'>
                        <Link to={"/admin/profile/edit/"+userProfile.id}>
                          <Button style={{ background: "none", border: "1px solid white", borderRadius: "20px" }}>
                            <img src={edit_logo} alt="Create New" style={{ width: "20px", marginRight: "5px", filter: "invert(100%)" }}/>
                            Edit
                          </Button>
                        </Link>          
                      </div>
                    </div>
                  </div>
                ))}
              </div>
                <div className="pagination">
                  <button
                    className={`pagination-button${currentPage === 1 ? ' disabled' : ''}`}
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <span className='pagination-span'>Previous</span>
                  </button>
                  <button
                    className={`pagination-button${indexOfLastItem >= filteredUsers.length ? ' disabled' : ''}`}
                    onClick={() => paginate(currentPage + 1)}
                    disabled={indexOfLastItem >= filteredUsers.length}
                  >
                    <span className='pagination-span'>Next</span>
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UserManagement;
