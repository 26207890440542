import React from "react";
import { createRoot } from 'react-dom/client';
import App from './app';
import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const BACKEND_URL = "https://mealpreppy-django.eduproject.eu/";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App tab="home" />);

export default BACKEND_URL;
