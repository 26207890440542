import React, {useEffect, createContext} from 'react';
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import AdminLayout from "layouts/Admin/Admin.js";
import AuthLayout from "layouts/Auth/Auth.js";
import { Suspense } from 'react';
import CustomFallback from 'components/Custom/CustomFallBack';
import axios from 'axios';
import BACKEND_URL from 'index.js';
import { useTranslation } from 'react-i18next';
import routes from 'routes.js';

const AdminContext = createContext();

const AdminProvider = ({ children }) => {
  const { i18n } = useTranslation();
  let access_token = localStorage.getItem('access_token');
  let refresh_token = localStorage.getItem('refresh_token');

  const refreshToken = async () => {
    try {
      const response = await axios.post(BACKEND_URL + '/users/login/refresh/',
      {
        refresh: refresh_token,
      },
      {
        headers: {
          'Accept-Language': i18n.language,
        },
      }
      );
      localStorage.setItem("access_token", response.data.access);
      access_token = response.data.access;
      localStorage.setItem("refresh_token", response.data.refresh);
      refresh_token = response.data.refresh
    } catch (error) {
      console.error('Refresh token failed:', error.message);
      window.location.href = '/auth/login'
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('is_staff');
    } finally {
      
    }
  };

  const checkLogin = async () => {
    for (const route of routes) {
      if (window.location.pathname === (route.layout + route.path)) {
        if (route.requires_login) {
          try {
            const response = await axios.get(BACKEND_URL + '/users/profile/', {
              headers: {
                'Authorization': 'Bearer ' + access_token,
              },
            });
            localStorage.setItem("is_staff", response.data.is_staff);
            localStorage.setItem("is_first_login", response.data.is_first_login);
          } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
              const errorCode = error.response.data.errors[0].code;
              if (errorCode === "token_not_valid") {
                try {
                  await refreshToken();
                  await checkLogin();
                  return;
                } catch (refreshError) {
                  console.error('Token refresh failed:', refreshError.message);
                  window.location.href = '/auth/login';
                  localStorage.removeItem('access_token');
                  localStorage.removeItem('refresh_token');
                  localStorage.removeItem('is_staff');
                }
              }
            }
          }
        }
        return;
      }
    }
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <AdminContext.Provider value={{ access_token }}>
      {children}
    </AdminContext.Provider>
  );
};

const App = () => {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/admin/*" element={<AdminLayout />} />
      <Route path="/auth/*" element={<AuthLayout />} />
      <Route
        path="*"
        element={<Navigate to="/auth/login" replace />}
      />
    </Routes>
  </BrowserRouter>
  );
};

export default function WrappedApp() {
    return (
      <Suspense fallback={<CustomFallback />}>
        <AdminProvider>
          <App />
        </AdminProvider>
      </Suspense>
    )
  }