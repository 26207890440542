import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
const CustomFallback = () => {
  return (
    <div
    style={{
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backgroundPosition: "center center",
      backgroundSize: "cover",
      zIndex: '9999',
    }}
  >
    <ThreeDots
      height={80}
      width={80}
      radius={9}
      color="#ffd617"
      ariaLabel="three-dots-loading"
    />
    </div>
  );
};

export default CustomFallback;