import React from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button
} from "reactstrap";
import { Link } from "react-router-dom";
import plus_logo from '../assets/img/plus.png';
import "../assets/customcss/News.css";

function Empty() {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card style={{ backgroundColor:"#12110b" }}>
              <CardHeader className="d-flex justify-content-between align-items-center">
                <CardTitle tag="h4" className="mb-0">Empty</CardTitle>
                <div>
                  <Link to="/admin/">
                    <Button style={{ background: "none", border: "1px solid white", borderRadius: "20px" }}>
                      <img src={plus_logo} alt="Create New" style={{ width: "20px", marginRight: "5px", filter: "invert(100%)" }}/>
                      Empty
                    </Button>
                  </Link>
                </div>
              </CardHeader>
              <CardBody>
            </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Empty;
