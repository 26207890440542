import React, { useState, useEffect } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button
} from "reactstrap";
import { Link } from "react-router-dom";
import plus_logo from '../assets/img/plus.png';
import edit_logo from '../assets/img/edit.png';
import check_mark from '../assets/img/check-mark.png';
import x_mark from '../assets/img/x-mark.png';
import axios from 'axios';
import BACKEND_URL from 'index';
import { useTranslation } from 'react-i18next';
import "../assets/customcss/News.css";

function News() {
  const { i18n } = useTranslation();
  let access_token = localStorage.getItem("access_token");
  let refresh_token = localStorage.getItem("refresh_token");
  const [news, setNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(4);

  const refreshToken = async () => {
    try {
      const response = await axios.post(BACKEND_URL + '/users/login/refresh/',
      {
        refresh: refresh_token,
      },
      {
        headers: {
          'Accept-Language': i18n.language,
        },
      }
      );
      localStorage.setItem("access_token", response.data.access);
      access_token = response.data.access;
      localStorage.setItem("refresh_token", response.data.refresh);
      refresh_token = response.data.refresh
    } catch (error) {
      console.error('Refresh token failed:', error.message);
      window.location.href = '/auth/login'
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('is_staff');
    } finally {
      
    }
  };

  const getNews = async () => {
    try {
      const response = await axios.get(BACKEND_URL + '/news/list/',
        {
        headers: {
          'Authorization': 'Bearer ' + access_token,
        },
      });

      setNews(response.data);
    } catch (error) {
      console.error('Save failed:', error.message);
      if (error.response && error.response.data && error.response.data.errors) {
        const errorCode = error.response.data.errors[0].code;
        if (errorCode === "token_not_valid") {
          try {
            await refreshToken();
            await getNews();
            return;
          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError.message);
            window.location.href = '/auth/login';
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('is_staff');
          }
        }
      }
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getNews();
  }, []);

  // Logic to slice news array based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNews = news.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card style={{ backgroundColor:"#12110b" }}>
              <CardHeader className="d-flex justify-content-between align-items-center">
                <div>
                  <CardTitle tag="h4" className="mb-0">News</CardTitle>
                </div>
                <div>
                  <Link to="/admin/create-news">
                    <Button style={{ background: "none", border: "1px solid white", borderRadius: "20px" }}>
                      <img src={plus_logo} alt="Create New" style={{ width: "20px", marginRight: "5px", filter: "invert(100%)" }}/>
                      Create New
                    </Button>
                  </Link>
                </div>
              </CardHeader>
              <CardBody>
                {currentNews.map(newsItem => (
                    <div key={newsItem.id} className="news-card">
                      <div className='news-header'>
                        <span className='news-title'>{newsItem.title}</span>
                        <div>
                          <img src={newsItem.enabled ? check_mark : x_mark} alt='news-state' className='news-state'/>
                          <span className='news-date'>{newsItem.news_date}</span>
                        </div>
                      </div>
                      <span className='news-description'>{newsItem.short_description}</span>
                      <div>
                        <div className='edit-news-button'>
                          <Link to={"/admin/news/edit/"+newsItem.id}>
                            <Button style={{ background: "none", border: "1px solid white", borderRadius: "20px" }}>
                              <img src={edit_logo} alt="Create New" style={{ width: "20px", marginRight: "5px", filter: "invert(100%)" }}/>
                              Edit
                            </Button>
                          </Link>          
                        </div>
                      </div>
                    </div>
                ))}
                <div className="pagination">
                  <button
                    className={`pagination-button${currentPage === 1 ? ' disabled' : ''}`}
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <span className='pagination-span'>Previous</span>
                  </button>
                  <button
                    className={`pagination-button${indexOfLastItem >= news.length ? ' disabled' : ''}`}
                    onClick={() => paginate(currentPage + 1)}
                    disabled={indexOfLastItem >= news.length}
                  >
                    <span className='pagination-span'>Next</span>
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default News;
