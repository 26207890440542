import React, { useState, useRef, useEffect } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,FormGroup, Input, InputGroup
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import { Link } from "react-router-dom";
import axios from 'axios';
import BACKEND_URL from 'index';
import save_logo from '../assets/img/save.png';
import "../assets/customcss/EditUserProfile.css";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import avatar_1 from '../assets/img/avatars/1.png';

function EditUserProfile() {
  const { i18n } = useTranslation();
  let access_token = localStorage.getItem("access_token");
  let refresh_token = localStorage.getItem("refresh_token");
  const [nickname, setNickname] = useState('');
  const [avatar, setAvatar] = useState('');
  const [email, setEmail] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [lastLogin, setLastLogin] = useState('');
  const {user_id} = useParams();
  const notificationAlertRef = useRef(null);
  
  const notify = (place) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            A user's nickname cannot be empty.
          </div>
        </div>
      ),
      type: "warning",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const refreshToken = async () => {
    try {
      const response = await axios.post(BACKEND_URL + '/users/login/refresh/',
      {
        refresh: refresh_token,
      },
      {
        headers: {
          'Accept-Language': i18n.language,
        },
      }
      );
      localStorage.setItem("access_token", response.data.access);
      access_token = response.data.access;
      localStorage.setItem("refresh_token", response.data.refresh);
      refresh_token = response.data.refresh
    } catch (error) {
      console.error('Refresh token failed:', error.message);
      window.location.href = '/auth/login'
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('is_staff');
    } finally {
      
    }
  };


  const handleSave = async () => {
    try {
      const formData = new FormData();
      if (!nickname) {
        notify("tc");
        return;
      }
      formData.append('avatar', avatar);
      formData.append('nickname', nickname);
      formData.append('height', height);
      formData.append('weight', weight);
      formData.append('age', age);
      formData.append('gender', gender);

      await axios.put(BACKEND_URL + '/profiles/edit/'+user_id, 
        formData,
        {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + access_token,
        },
      });

      window.location.href = "/admin/user-management"

    } catch (error) {
      console.error('Save failed:', error.message);
      console.log(error.message);
      if (error.response && error.response.data && error.response.data.errors) {
        const errorCode = error.response.data.errors[0].code;
        if (errorCode === "token_not_valid") {
          try {
            await refreshToken();
            await handleSave();
            return;
          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError.message);
            window.location.href = '/auth/login';
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('is_staff');
          }
        }
      }
    }
  };

  const getUserProfileDetails = async () => {
    try {
      const response = await axios.get(BACKEND_URL + '/profiles/get/' + user_id,
        {
        headers: {
          'Authorization': 'Bearer ' + access_token,
        },
      });
      setNickname(response.data.nickname);
      setAvatar(response.data.avatar);
      setEmail(response.data.user_email);
      setHeight(response.data.height);
      setWeight(response.data.weight);
      setAge(response.data.age);
      setGender(response.data.gender);
      if(response.data.user_last_login == null){
        setLastLogin("Never logged in.");
      } else{
        const responseDate = new Date(response.data.user_last_login);
        const formattedDate = responseDate.toISOString().split('T')[0];
        setLastLogin(formattedDate);
      }
    } catch (error) {
      console.error('Save failed:', error.message);
      console.log(error);
      if (error.response && error.response.data && error.response.data.errors) {
        const errorCode = error.response.data.errors[0].code;
        if (errorCode === "token_not_valid") {
          try {
            await refreshToken();
            await getUserProfileDetails();
            return;
          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError.message);
            window.location.href = '/auth/login';
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('is_staff');
          }
        }
      }
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getUserProfileDetails();
  }, []);
  
  return (
    <>
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>
          <Col md="12">
            <Card style={{ backgroundColor:"#12110b" }}>
            <CardHeader className="d-flex justify-content-between align-items-center">
                <div>
                  <CardTitle tag="h4" className="mb-0">Edit User Profile</CardTitle>
                  <Link to="/admin/user-management" style={{ marginRight: "10px", color:"white" }}>
                      Back
                  </Link>         
                </div>
                <div>
                  <Button onClick={() => {handleSave()}} style={{ background: "none", border: "1px solid white", borderRadius: "20px" }}>
                    <img src={save_logo} alt="Save" style={{ width: "20px", marginRight: "5px", filter: "invert(100%)" }}/>
                    Save
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
              <div className="large-card">
                <CardTitle tag="h4" className="mb-2">In-App Identity</CardTitle>
                <div className="in-app-identity-div">
                <img src={avatar_1} alt="avatar" style={{ width: "200px", marginRight: "5px" }}/>
                <CardTitle tag="h4" className="mb-0">{nickname}</CardTitle>  
                </div>
              </div>

              <div className="large-card">
                <CardTitle tag="h4" className="mb-2">Account Information</CardTitle>
                <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                <FormGroup className="email-div-formgroup">
                  <div className="email-div">
                        <label htmlFor="email" className="form-label">Email</label>
                        <InputGroup className="input-group-alternative">
                        <Input
                            id="email"
                            placeholder="Email"
                            type="text"
                            autoComplete="new-email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            maxLength={100}
                            className="input-field"
                          />
                    </InputGroup>
                  </div>
                </FormGroup>
                <FormGroup className="last-login-div-formgroup">
                  <div className="last-login-div">
                        <label htmlFor="last-login" className="form-label">Last Login</label>
                        <InputGroup className="input-group-alternative">
                        <Input
                            id="last-login"
                            placeholder="Last login"
                            type="text"
                            autoComplete="new-last-login"
                            value={lastLogin}
                            onChange={(e) => setLastLogin(e.target.value)}
                            maxLength={100}
                            className="input-field"
                            style={{ pointerEvents:'auto', opacity: 1, backgroundColor:'rgba(0,0,0,0)', color:'white'}}
                            disabled={true}
                          />
                    </InputGroup>
                  </div>
                </FormGroup>
                </div>
              </div>

              <div className="large-card">
                <CardTitle tag="h4" className="mb-2">Profile Information</CardTitle>
                <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                <FormGroup className="height-div-formgroup">
                  <div className="height-div">
                        <label htmlFor="height" className="form-label">Height</label>
                        <InputGroup className="input-group-alternative">
                        <Input
                            id="height"
                            placeholder="Height"
                            type="text"
                            autoComplete="new-height"
                            value={height}
                            onChange={(e) => setHeight(e.target.value)}
                            maxLength={100}
                            className="input-field"
                          />
                    </InputGroup>
                  </div>
                </FormGroup>
                <FormGroup className="weight-div-formgroup">
                  <div className="weight-div">
                        <label htmlFor="weight" className="form-label">Weight</label>
                        <InputGroup className="input-group-alternative">
                        <Input
                            id="weight"
                            placeholder="Weight"
                            type="text"
                            autoComplete="new-weight"
                            value={weight}
                            onChange={(e) => setWeight(e.target.value)}
                            maxLength={100}
                            className="input-field"
                          />
                    </InputGroup>
                  </div>
                </FormGroup>
                </div>
                <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                <FormGroup className="age-div-formgroup">
                  <div className="age-div">
                        <label htmlFor="age" className="form-label">Age</label>
                        <InputGroup className="input-group-alternative">
                        <Input
                            id="age"
                            placeholder="Age"
                            type="text"
                            autoComplete="new-age"
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                            maxLength={100}
                            className="input-field"
                          />
                    </InputGroup>
                  </div>
                </FormGroup>
                <FormGroup className="gender-div-formgroup">
                  <div className="gender-div">
                        <label htmlFor="gender" className="form-label">Gender</label>
                        <InputGroup className="input-group-alternative">
                        <Input
                            id="gender"
                            placeholder="Gender"
                            type="text"
                            autoComplete="new-gender"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            maxLength={100}
                            className="input-field"
                          />
                    </InputGroup>
                  </div>
                </FormGroup>
                </div>
              </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default EditUserProfile;
