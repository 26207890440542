import {
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BACKEND_URL from '../index.js';
import '../assets/customcss/Login.css'

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState("")

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let access = localStorage.getItem("access_token");
    if(access != null){
      window.location.href = '/admin/dashboard'
    }
  },[]);

  const handleLogin = async () => {
    //send post
    try {
      const response = await axios.post(BACKEND_URL + '/users/login/', {
        email: email,
        password: password
      });
      //receive and store access tokens
      localStorage.setItem("access_token", response.data.access);
      localStorage.setItem("refresh_token", response.data.refresh);
      window.location.href = '/admin/dashboard'
      
    } catch (error) {
      if(error.response && error.response.data && error.response.data.errors && error.response.data.errors[0]){
        if(error.response.data.errors[0].detail === "No active account found with the given credentials"){
          setError("Invalid credentials");
        } else if (error.response.data.errors[0].detail === "This field may not be blank."){
          setError("Please fill in all of the fields before proceeding");
        } else {
          setError("Error communicating login to server.");
        }
      }
    }
  };

  return (
    <>
      <Col lg="5">
        <div className="main-div">
          <div className="content-div">
            <CardBody>
                <div className="brand-div">
                  <img
                    alt="..."
                    src={require("../assets/img/scuffed_logo.png")}
                    className="brand-img"
                  />
                </div>
                <div className="sign-hint-div">
                  <span className="sign-hint">
                    {"MealPreppy"}
                  </span>
                </div>
                <div className="sign-hint-div">
                </div>
                <Form role="form">
                <FormGroup className="mb-3">
                  <div className="login-div">
                  <label htmlFor="email" className="form-label">Email</label>
                  <InputGroup className="input-group-alternative">
                      <Input
                          id="email"
                          placeholder="Email"
                          type="email"
                          autoComplete="new-email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          maxLength={400}
                          className="input-field"
                      />
              </InputGroup>
              </div>
                </FormGroup>
                <FormGroup>
                  <div className="login-div">
                    <label htmlFor="password" className="form-label">Password</label>
                    <InputGroup className="input-group-alternative">
                        <Input
                            id="password"
                            placeholder="Password"
                            type="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            maxLength={400}
                            className="input-field"
                        />
                    </InputGroup>
                  </div>
                </FormGroup>
                  <Row className="row-style">
                    <Col className="centered-collumn">
                        <small className="error-style">{error}</small>
                    </Col>
                    </Row>
                  <div className="sign-in-button-div">
                    <button 
                    type="button" 
                    onClick={() => handleLogin()}
                    className="sign-in-button">
                      {"Sign in"}
                    </button>
                  </div>
                </Form>
            </CardBody>
          </div>
        </div>
      </Col>
    </>
  );
};

export default Login;
